* {
    box-sizing: border-box;
}
h1 {
    color: #4286f4;
    margin-top: 30px;
    margin-bottom: 60px;
}
/* div {
    text-align: center;
    width: 80%;
    margin: 0 auto;
} */
input {
    width: 90%;
    margin: 10px;
    padding: 18px 10px;
    border-radius: 4px;
    border: 2px solid #4e5766;
    box-shadow: 1px 0.5px #888888;
    font-size: 18px;
    color: rgb(0, 0, 0);
}

tr {
    /* width: 20%; */
}

#tr-header{
    font-size: 20px;
    background-color: #868f9e;
}

.hovertext {
    position: relative;
    color: white;
    border-bottom: 1px dotted white;
  }
  
.hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 140px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 110%;
}
  
.hovertext:hover:before {
    background-color: #ffffff;
    opacity: 1;
    visibility: visible;
}

#searchPage {
    align-self: center;
    width: 80%;
    margin: 0 auto;
}

#searchInput {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding-bottom: 20px;
}

#table {
    background: rgba(34, 33, 33, 0.534);
    width: 100%;
    padding: 10px;
    padding: 18px 10px;
    border-radius: 4px;
    /* border: 1px solid #4e5766; */
    /* box-shadow: 1px 0.5px #888888; */
    font-size: 18px;
    color: #e7e1e1;
}

.sourceColumn {
    width: 12%;
    text-align: center;
}

.nameColumn {
    text-align: left;
}

.bonusColumn {
    text-align: left;
}

a:link {
    color: #8c8cc4;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:visited {
    color: #8c8cc4;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
  }
  
  a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }

#customSearchSelector {
    /* display: flex; */
    position: fixed;
    top: 1em;
    right: 1em;
}

#resultCount {
    position: flex;
    color: white;
    text-align: center;
    padding-bottom: 1%;
    /* background-color: red; */
}